//logos associates
import auroraLogo from "../../images/associates-certification/aurora-logo.jpg";
import ckgLogo from "../../images/associates-certification/ckg-logo.png";
import cronyLogo from "../../images/associates-certification/crony-logo.jpg";
import dAndJLogo from "../../images/associates-certification/d&j-logo.jpg";
import ntcLogo from "../../images/associates-certification/ntc-logo.jpg";
import tiemLogo from "../../images/associates-certification/tiem-logo.png";
//portraits associates
import emrah from "../../images/associates-certification/emrah-portrait.jpg";
import michael from "../../images/associates-certification/michael-portrait.jpg";
import santha from "../../images/associates-certification/santha-portrait.jpg";
import teddy from "../../images/associates-certification/teddy-portrait.jpg";
import usman from "../../images/associates-certification/usman-portrait.jpg";
import vincent from "../../images/associates-certification/vincent-portrait.jpg";

export const associates = (t) => {
    return {
        cardsInfo: {
            associates: [
              {
                logoImg: auroraLogo,
                logoDescription: t("associatesCertificates.auroraLogo"),
                description: t("associatesCertificates.auroraDescription"),
                photo: teddy,
                testimonyName: t("associatesCertificates.auroraTestimony"),
                title: t("associatesCertificates.auroraTitle"),
              },
              {
                logoImg: ckgLogo,
                logoDescription: t("associatesCertificates.ckgLogo"),
                description:t("associatesCertificates.ckgDescription"),
                photo: vincent,
                testimonyName: t("associatesCertificates.ckgTestimony"),
                title: t("associatesCertificates.ckgTitle")
              },
              {
                logoImg: cronyLogo,
                logoDescription: t("associatesCertificates.cronyLogo"),
                description:t("associatesCertificates.cronyDescription"),
                photo: santha,
                testimonyName: t("associatesCertificates.cronyTestimony"),
                title: t("associatesCertificates.cronyTitle"),
              },
              {
                logoImg: dAndJLogo,
                logoDescription: t("associatesCertificates.dAndJLogo"),
                description:t("associatesCertificates.dAndJDescription"),
                photo: michael,
                testimonyName: t("associatesCertificates.dAndJTestimony"),
                title: t("associatesCertificates.dAndJTitle"),
              },
              {
                logoImg: ntcLogo,
                logoDescription: t("associatesCertificates.ntcLogo"),
                description:t("associatesCertificates.ntcDescription"),
                photo: usman,
                testimonyName: t("associatesCertificates.ntcTestimony"),
                title: t("associatesCertificates.ntcTitle"),
              },
              {
                logoImg: tiemLogo,
                logoDescription: t("associatesCertificates.tiemLogo"),
                description:t("associatesCertificates.tiemDescription"),
                photo: emrah,
                testimonyName: t("associatesCertificates.tiemTestimony"),
                title: t("associatesCertificates.tiemTitle"),
              },
            ],
            textCenter: true,
            topDiagonal: false,
            backgroundGrey: false,
          }
    }
};