import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/certificatesHeader.jpg";
import image1 from "../images/certificates-results-1.png";
import image2 from "../images/certificates-results-2.png";
import image3 from "../images/certificates-results-3.png";
import partners from "../images/centered-partners.jpg";

export const pageTexts = (t) => {
  return {
    textBased: true,
    topInfo: {
      title: {
        text: t("certification.certificatesTopTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
          text: t("certification.certificatesPageTopSubTitle"),
          colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      full: true,
      image: {
        src: TopBackground,
        alt: t("certification.imgAlt"),
        bottomPosition: true,
        bigHeight: true,
      },
    },
    image1:{
      src: image1,
      alt: t("centeredPage.suppliersAlt")
    },
    text1: {
      title: {
        text: t("centeredPage.text1Title"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      subtitle: {
        text: t("centeredPage.text1Text"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: false,
    },
    image2:{
      src: image2,
      alt: t("centeredPage.findSuppliersAlt")
    },
    text2: {
      title: {
        text: t("centeredPage.text2Title"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      subtitle: {
        text: t("centeredPage.text2Text"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: false,
    },
    image3:{
      src: image3,
      alt: t("centeredPage.requestQuotationAlt")
    },
    text3: {
      title: {
        text: t("centeredPage.text3Title"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      subtitle: {
        text: t("centeredPage.text3Text"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: false,
    },
    aboutUs: {
      title: {
        text: t("centeredPage.aboutUs"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("centeredPage.aboutUsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: false,
    },
    partnersTitle:{
      title: {
        text: t("centeredPage.partnersTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
    },
    partners:{
      src: partners,
      alt: t("centeredPage.partnersAlt")
    },
    cta: {
      text: t("centeredPage.ctaText"),
      url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
      extraClass: "bigWidth"
    }
  };
};
